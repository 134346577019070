body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after {
  clear: both;
}

.dialogBoxCls > div {
  background-color: rgba(240, 255, 255, 0.4);
}
.dialogBoxCls > div > div {
  box-shadow: none;
  border-radius: 0px;
  border: #00cdbe thin solid;
  padding: 30px;
  max-width: 650px;
}
.dialogBoxCls h2 {
  color: #00cdbe;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  width: 80%;
}

.detailDialogCls > div {
  background-color: rgba(240, 255, 255, 0.4);
}
.detailDialogCls > div > div {
  box-shadow: none;
  border-radius: 0px;
  border: #00cdbe thin solid;
  padding: 30px;
  max-width: 100%;
  display: block;
  position: relative;
}

.clubsUploadBtn {
  font-family: "Montserrat", sans-serif !important;
  text-transform: capitalize !important;
  background-color: #ffffff !important;
  color: #00cdbe !important;
  width: 133px !important;
  border-radius: 39px !important;
  outline: none !important;
  margin-top: 0.5px !important;
  float: right;
}
.clubsUploadBtn:disabled {
  background: #ededed !important;
  color: #7c7c7c !important;
}

.userDetailDialog {
  display: flex;
  flex-direction: column;
  background-color: rgba(240, 255, 255, 0.4);
  border: 1px solid #00cdbe;
  padding: 30px;
  height: 100%;
  overflow-y: auto;
  gap: 0.7rem;
  width: 450px;
  margin-bottom: 100px;
}
.userDetailDialog label {
  color: #00cdbe;
  font-weight: bold;
}

.btnWrapper {
  display: flex;
  margin-top: 10px;
  position: fixed;
  margin-bottom: 33px;
  padding-bottom: 20px;
  bottom: 0;
  background-color: white;
  width: 450px;
}

.detailDialogButton {
  display: inline-flex;
  margin-top: 20px;
  height: 60px;
  gap: 15px;
}
.detailDialogButton button {
  padding: 10px 15px !important;
  font-size: 12px;
  border: 0px;
}

.manuallyBtnCls {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  background-color: #00cdbe !important;
  color: #ffffff !important;
  width: 145px !important;
  padding: 15px 0px !important;
  border-radius: 39px !important;
  outline: none !important;
}

.SaveBtnCls {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  background-color: #00cdbe !important;
  color: #ffffff !important;
  width: 145px !important;
  padding: 15px 0px !important;
  border-radius: 39px !important;
  outline: none !important;
}

.BannedCheckbox {
  color: #00cdbe !important;
}

.BannedCheckboxLabel {
  color: #00cdbe !important;
}

.dialogContent {
  padding: 1px 24px 20px;
  width: 80%;
}
.dialogContent p {
  color: #00cdbe;
  font-size: 16px;
}

.dialogButtons {
  justify-content: flex-start !important;
  padding: 7px 13px;
}
.dialogButtons button {
  border-radius: 48px;
  padding: 11px 25px;
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 0.03em;
  outline: none !important;
}
.dialogButtons button.confirmBtn {
  background-color: #fcab10 !important;
  color: #ffffff;
}
.dialogButtons button.cancelBtn {
  background-color: transparent !important;
  color: #fcab10;
  text-decoration: underline;
}

.dialogCloseBtn {
  background: transparent;
  border: none;
  float: right;
  padding: 0px;
  cursor: pointer;
  outline: none !important;
  text-align: right;
}

.ErrorPage {
  height: 100vh;
  display: flex;
  background: #f0ffff;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.ErrorPage > div {
  max-width: 80%;
}
.ErrorPage .errorLbl {
  font-size: 111px;
  font-weight: 600;
  color: #00cdbe;
}
.ErrorPage a {
  background-color: #00cdbe;
  color: #ffffff;
  padding: 9px 15px;
  font-weight: 600;
  margin: 19px 0px;
  display: block;
  text-decoration: none;
}

.maincontainer {
  height: 100vh;
}

.logoAndHeading {
  height: 250px;
  background: #00cdbe;
  padding: 49px 10% 39px 10%;
  text-align: center;
}
.logoAndHeading .ConfirmationHeading {
  color: #ffffff;
  margin-top: 19px;
  font-size: 32px;
  font-weight: 500;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 25px;
}

.bodyContainer .ConfirmationText {
  margin-top: 25px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
}
.bodyContainer .confirmBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 25px;
}
.bodyContainer .confirmBtnContainer :hover {
  background: none !important;
  outline: none !important;
}
.bodyContainer .confirmBtnContainer .confirmBtn {
  color: white;
  background-color: #9253fc !important;
  border-radius: 25px;
  height: 50px;
  width: 190px !important;
  font-size: 16px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
}

.InvalidCls {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  font-size: 20px;
  color: red;
}

.footer {
  background: #f3f3f3;
  border-top: 1px #d6d6d6 solid;
  padding: 25px 10%;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #363636;
  font-family: Arial, sans-serif;
  -ms-border-radius: 0;
  border-radius: 0;
  font-weight: normal;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.search input {
  border: 1px solid #00cdbe;
  background-color: none;
  color: black;
  margin: 1rem;
  padding: 1rem;
  width: 10vw;
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-family: "Montserrat", sans-serif;
}

.field-spacing {
  margin-bottom: 18px !important;
}

.headerView {
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: #ffffff;
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid #00cdbe;
}
.headerView div.logoCnt {
  margin: 0;
  display: flex;
  align-content: center;
  align-items: center;
}
.headerView ul {
  display: flex;
  align-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
}
.headerView ul li > a {
  color: #fcab10;
  text-decoration: none;
  margin: 0px 15px;
  font-weight: bold;
}
.headerView li {
  position: relative;
}
.headerView li .dropDown {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 40px;
  font-weight: bold;
  border: 1px solid #00CDBE;
}

.activeCls {
  color: #00cdbe !important;
}

.table {
  border: 1px solid #00cdbe;
  background-color: #ffffff;
  color: #00cdbe;
  margin-top: 1rem;
  width: 70vw !important;
  overflow-x: auto;
}
.table .Disabled td {
  color: #d3d3d3;
}
.table .Enable {
  color: #00cdbe;
}

.table td,
.table th {
  border: none !important;
  color: #00cdbe;
  font-family: "Montserrat", sans-serif;
}

.tableRow {
  border-bottom: #00cdbe 1px solid;
}
.tableRow th {
  display: flex;
  justify-content: start !important;
  cursor: pointer;
  border-bottom: 0 !important;
  font-weight: bold;
}
.tableRow th svg {
  color: #fcab10;
}
.tableRow th span {
  padding-top: 2px;
  display: inline-block;
  float: left;
  font-size: 15px;
}

.tableBody td {
  font-size: 15px;
}

.table-umii .tableBody tr {
  cursor: pointer;
}

.table-umii {
  display: flex !important;
  flex-direction: column;
}
.table-umii .tableBody {
  display: flex;
  flex-direction: column;
}
.table-umii .tableBody tr {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 60px;
}
.table-umii .tableBody tr td:nth-child(1) {
  max-width: 20% !important;
  min-width: 20% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-umii .tableBody tr td:nth-child(2) {
  max-width: 25% !important;
  min-width: 25% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-umii .tableBody tr td:nth-child(3) {
  max-width: 25% !important;
  min-width: 25% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-umii .tableBody tr td:nth-child(4) {
  max-width: 15% !important;
  min-width: 15% !important;
}
.table-umii .tableBody tr td:nth-child(5) {
  max-width: 15% !important;
  min-width: 15% !important;
}
.table-umii .tableHead {
  display: flex;
  flex-direction: row;
}
.table-umii .tableHead .tableRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.table-umii .tableHead .tableRow th:nth-child(1) {
  max-width: 20% !important;
  min-width: 20% !important;
}
.table-umii .tableHead .tableRow th:nth-child(2) {
  max-width: 25% !important;
  min-width: 25% !important;
}
.table-umii .tableHead .tableRow th:nth-child(3) {
  max-width: 25% !important;
  min-width: 25% !important;
}
.table-umii .tableHead .tableRow th:nth-child(4) {
  max-width: 15% !important;
  min-width: 15% !important;
}
.table-umii .tableHead .tableRow th:nth-child(5) {
  max-width: 15% !important;
  min-width: 15% !important;
}

.loginContainer {
  background-color: #00CDBE;
  display: flex;
  flex: 1;
  height: 100vh;
  color: #fff;
}

.outlineLeft {
  padding: 30px 0px 35px 20px;
  border-right: 1.5px solid #fff;
  display: flex;
  flex: 1;
}

.outlineRight {
  padding: 30px 20px 35px 0px;
  border-left: 1.5px solid #fff;
  display: flex;
  flex: 1;
}

.logo, .login {
  flex: 1;
  height: 100%;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo {
  border-left: 3px solid #fff;
}

.login {
  border-right: 3px solid #fff;
  display: flex;
  flex-direction: column;
}
.login form {
  text-align: center;
  width: 70%;
  max-width: 350px;
  margin: 0 auto;
}
.login h2 {
  font-weight: 800;
  font-size: 29px;
  margin-bottom: 45px;
}

.loginBtn {
  font-family: "Montserrat", sans-serif !important;
  font-size: 19px !important;
  font-weight: 800 !important;
  text-transform: capitalize !important;
  background-color: #ffffff !important;
  color: #00cdbe !important;
  width: 133px !important;
  padding: 15px 0px !important;
  border-radius: 39px !important;
  outline: none !important;
  margin-top: 35px !important;
}

.uploadBtn {
  font-family: "Montserrat", sans-serif !important;
  text-transform: capitalize !important;
  background-color: #ffffff !important;
  color: #00cdbe !important;
  width: 133px !important;
  border-radius: 39px !important;
  outline: none !important;
  margin-top: 15px !important;
  float: right;
}
.uploadBtn:disabled {
  background: #ededed !important;
  color: #7c7c7c !important;
}

.forgetPassLink {
  color: #ffffff !important;
  font-weight: 600;
  text-decoration: underline !important;
  display: block !important;
  margin-top: 55px !important;
}

.loginTextfield label {
  color: #ffffff !important;
  font-size: 17px;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
}
.loginTextfield div {
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
}
.loginTextfield div input {
  font-size: 19px;
  font-weight: 600;
  padding-left: 21px;
}
.loginTextfield div input:-webkit-autofill, .loginTextfield div input:-webkit-autofill:hover, .loginTextfield div input:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #00cdbe inset;
  transition: background-color 5000s ease-in-out 0s;
}
.loginTextfield div:before {
  border-bottom: 3px solid rgb(255, 255, 255);
}
.loginTextfield div:hover:not(.MuiInput-disabled-26):not(.MuiInput-focused-25):not(.MuiInput-error-28):before {
  border-bottom: 3px solid rgb(255, 255, 255);
}
.loginTextfield div:after {
  border-bottom: 3px solid rgb(255, 255, 255);
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  background-image: none !important;
  color: rgb(255, 255, 255) !important;
}

.dashHeader {
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: #ffffff;
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid #00cdbe;
}
.dashHeader h2 {
  margin: 0;
  display: flex;
  align-content: center;
  align-items: center;
}
.dashHeader h3 {
  margin: 0;
  display: flex;
  align-content: center;
  align-items: center;
  font-weight: 600;
}
.dashHeader ul {
  display: flex;
  align-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
}
.dashHeader ul li > a {
  color: #f7bd1d;
  text-decoration: none;
  padding-right: 1rem;
  font-weight: 600;
}

.filter input[type=search]::-webkit-search-cancel-button, .disableFilter input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.filter label, .disableFilter label {
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}
.filter input[type=checkbox], .disableFilter input[type=checkbox] {
  -webkit-appearance: initial;
  appearance: initial;
  width: 25px;
  height: 25px;
  border: 1px solid #00cdbe;
  background: transparent;
  position: relative;
  outline: none;
  margin: 0 16px;
}
.filter input[type=checkbox]:checked, .disableFilter input[type=checkbox]:checked {
  background: #00cdbe;
}
.filter input[type=checkbox]:checked:after, .disableFilter input[type=checkbox]:checked:after {
  content: "x";
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.search input {
  border: 1px solid #00cdbe;
  background-color: none;
  color: black;
  margin: 1rem;
  padding: 1rem;
  width: 12vw;
  align-self: flex-start;
  font-family: "Montserrat", sans-serif;
}
.search input:focus {
  outline: none;
}
.search input::placeholder {
  color: #00cdbe;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.filter, .disableFilter {
  margin-top: 23px;
}

.downloadCsv {
  position: absolute !important;
  margin-top: -20px !important;
  color: #fcab10 !important;
  font-weight: bold !important;
  border: 0 !important;
  outline: none !important;
  padding-left: 0 !important;
  margin-left: 1px !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}

.activeInactiveCls {
  border: 1px solid #00cdbe;
  background-color: #ffffff;
  color: #00cdbe;
  margin: 1rem;
  padding: 1rem;
  font-size: 14px;
  font-weight: 600;
  margin-top: 26px;
}

.noneEmail {
  color: #ff0000 !important;
}

.yellowEmail {
  color: #fcab10 !important;
}

.resetLbl {
  float: right;
}
.resetLbl a {
  margin-top: 0px !important;
}

.cursorDefault {
  cursor: default !important;
}

.dashContainer {
  background-color: #f0ffff;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  color: #00cdbe;
}

.headerTitle {
  margin: 0;
  display: flex;
  align-content: center;
  align-items: center;
  font-weight: bold;
}

.mainContent,
.dashView {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.mainContent {
  padding: 2rem;
}

.uniLeftNav {
  align-self: start !important;
  flex-direction: column;
  width: 100%;
}

.select {
  width: 75vw !important;
  padding-bottom: 1rem;
}

[class$=-control] {
  border-color: #00cdbe !important;
  box-shadow: none !important;
  border: none;
  border-radius: 0 !important;
  font-size: 14px;
  height: 100%;
}

[class$=-indicatorSeparator] {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.brnadingWrapper {
  flex-direction: column;
}
.brnadingWrapper h2 {
  font-size: 13px !important;
  font-weight: 600 !important;
}
.brnadingWrapper .brandingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brnadingWrapper .brandingHeader .logoUploadBtn {
  float: left;
  padding: 6px 8px;
  margin-left: 15px;
  border: 0;
  width: auto !important;
  outline: none !important;
}
.brnadingWrapper .brandingHeader img {
  border: 1px solid #00cdbe;
  padding: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.brnadingWrapper .toggleSwitch {
  height: 24px;
}
.brnadingWrapper .text-center {
  font-size: 14px;
  margin-top: 33px !important;
  display: inline-block;
  border: 1px dashed;
  padding: 10px;
}
.brnadingWrapper .logoUploadBtn {
  text-transform: capitalize !important;
  background-color: #ffffff !important;
  color: #00cdbe !important;
  width: 133px !important;
  border-radius: 39px !important;
  border: 1px solid #00cdbe;
  outline: none !important;
  margin-top: 0 !important;
}
.brnadingWrapper .logoRemoveBtn {
  text-transform: capitalize !important;
  background-color: #ffffff !important;
  color: #00cdbe !important;
  width: 125px !important;
  outline: none !important;
  margin-top: 0 !important;
  border: 0;
}

.metrics {
  margin: 0 !important;
  padding: 0 !important;
}
.metrics div {
  flex: 1;
  border: 1px solid #00cdbe;
  background-color: #ffffff;
  text-align: center;
  margin: 0 5px;
  border-radius: 15px;
  padding: 15px 0;
}

.date,
.metrics {
  margin: 1rem;
  padding: 1rem;
  display: flex;
  align-self: center;
  justify-content: space-between;
}
.date div,
.metrics div {
  margin-bottom: 45px;
}
.date p,
.metrics p {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
}
.date h2,
.metrics h2 {
  margin: 0;
  font-size: 45px;
  font-weight: 600;
  margin-top: 5px;
}

.date div {
  margin-bottom: 5px;
}
.date h2 {
  font-size: 28px;
}
.date a {
  color: #fcab10 !important;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline !important;
  display: inline-block;
  margin-top: 40px;
  cursor: pointer;
}
.date button {
  color: #fcab10 !important;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline !important;
  display: inline-block;
  margin-top: 45px;
  cursor: pointer;
  border: none;
  background: none;
}

.table.uniView {
  width: 70vw !important;
}

.date {
  border: 1px solid #00cdbe;
  background-color: #ffffff;
  color: #00cdbe;
}

.datePicker {
  position: relative;
}
.datePicker .react-datepicker {
  border: 1px solid #00cdbe;
  border-radius: 0;
  font-family: "Montserrat", sans-serif;
  padding: 1rem 0;
  position: absolute;
  left: -17px;
  top: 15px;
}
.datePicker .react-datepicker:before, .datePicker .react-datepicker:after {
  content: " ";
  display: block;
  position: absolute;
  top: -12px;
  left: 17px;
  z-index: 2;
  width: 0;
  height: 0;
  overflow: hidden;
  border: solid 12px transparent;
  border-top: 0;
  border-bottom-color: #ffffff;
}
.datePicker .react-datepicker:before {
  top: -13px;
  z-index: 1;
  border-bottom-color: #00cdbe;
}
.datePicker .react-datepicker__month-container {
  width: 100%;
}
.datePicker .react-datepicker__year-dropdown {
  background-color: #ffffff;
  color: #00cdbe;
}
.datePicker .react-datepicker__navigation--previous {
  border-right-color: #fcab10;
  margin-top: 1rem;
}
.datePicker .react-datepicker__navigation:focus {
  outline: none;
}
.datePicker .react-datepicker__navigation--next {
  border-left-color: #fcab10;
  margin-top: 1rem;
}
.datePicker .react-datepicker__header {
  background-color: #ffffff;
  border-bottom: none;
}
.datePicker .react-datepicker__current-month {
  color: #00cdbe;
}
.datePicker .react-datepicker__day-names {
  display: none;
}
.datePicker .react-datepicker__day {
  color: #00cdbe;
  width: 1.27rem;
  font-size: 10px;
  font-weight: 700;
}
.datePicker .react-datepicker__day--disabled {
  color: #d3d3d3;
}
.datePicker .react-datepicker__day--selected {
  background-color: #ffffff;
  border: none;
  color: purple;
  font-weight: 600;
}
.datePicker .react-datepicker__day--selected:hover {
  background-color: #ffffff;
}
.datePicker .react-datepicker__day--selected:focus, .datePicker .react-datepicker__day--selected:active {
  font-weight: 800;
}
.datePicker .react-datepicker__day--today {
  color: #00cdbe;
  font-weight: 600;
}

.disableFilter label {
  cursor: pointer;
  font-size: 15px;
}
.disableFilter input[type=checkbox] {
  appearance: none !important;
  width: 25px;
  height: 25px;
  margin: 0px 9px 0px 0px;
  cursor: pointer;
}
.disableFilter input[type=checkbox]:checked:after {
  top: 49%;
}

.toggleWrapper {
  border-top: 1px solid #d1fffc;
  margin-top: 20px;
  padding-top: 12px;
}
.toggleWrapper .toggleButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}
.toggleWrapper .toggleButtons span {
  font-size: 14px;
  font-weight: 700;
}

.adminDialog h2 {
  width: 100%;
  font-size: 30px;
  margin-bottom: 23px;
}
.adminDialog .textField {
  width: 100%;
  padding: 10px;
  border: 1px solid #00cdbe;
}
.adminDialog .gridWrapper {
  margin-top: 20px;
}
.adminDialog .gridWrapper .campusGrid, .adminDialog .gridWrapper .gridHeading {
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style: none;
  padding: 0 23px 8px 23px;
  margin: 0 0 8px 0;
  border-bottom: 1px solid #ccc;
}
.adminDialog .gridWrapper .campusGrid li:nth-child(1), .adminDialog .gridWrapper .gridHeading li:nth-child(1) {
  flex: 1;
  font-size: 14px;
}
.adminDialog .gridWrapper .campusGrid .gridIcons svg, .adminDialog .gridWrapper .gridHeading .gridIcons svg {
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
  color: #00cdbe;
}
.adminDialog .gridWrapper .campusGrid .gridIcons svg:nth-child(2), .adminDialog .gridWrapper .gridHeading .gridIcons svg:nth-child(2) {
  fill: #f95b58 !important;
  color: #00cdbe;
}
.adminDialog .gridWrapper .gridHeading {
  border-bottom: 1px solid #00cdbe;
}
.adminDialog .gridWrapper .gridHeading li {
  font-weight: 700;
}
.adminDialog .saveContainer {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
.adminDialog .saveContainer .saveRecordBtn {
  position: absolute;
  top: 10px;
  right: 0;
}
.adminDialog .saveContainer .saveRecordBtn button {
  padding: 5px 22px;
}

.outlineContainer {
  padding: 50px 20px 35px 30px;
  border: 1.5px solid #fff;
  background-color: #00cdbe;
  display: flex;
  height: 100%;
  color: #fff;
  text-align: center;
}
.outlineContainer .errorMessage {
  display: flex;
  justify-content: flex-start;
  color: red;
}

.EditContainer {
  padding: 35px 20px 35px 20px;
  border: 1.5px solid #fff;
  background-color: #00cdbe;
  width: 450px;
  height: 100%;
  color: #fff;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
}
.EditContainer .errorMessage {
  display: flex;
  justify-content: flex-start;
  color: red;
}

.checkBoxLabel {
  color: #fff !important;
}

.formContainer .closeBtn {
  margin-left: 380px;
  margin-top: -25px;
  cursor: pointer;
}
.formContainer h2 {
  font-weight: 800;
  font-size: 29px;
  margin-bottom: 45px;
  padding-top: 10px;
  color: white;
}
.formContainer .formTextField {
  margin-left: 5px;
}
.formContainer .formTextField label {
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 600 !important;
  font-family: "Montserrat", sans-serif !important;
}
.formContainer .formTextField div {
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
}
.formContainer .formTextField div input {
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
}
.formContainer .formTextField div input:-webkit-autofill, .formContainer .formTextField div input:-webkit-autofill:hover, .formContainer .formTextField div input:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}
.formContainer .formTextField div:before {
  border-bottom: 3px solid rgb(255, 255, 255);
}
.formContainer .formTextField div:hover:not(.MuiInput-disabled-26):not(.MuiInput-focused-25):not(.MuiInput-error-28):before {
  border-bottom: 3px solid rgb(255, 255, 255);
}
.formContainer .formTextField div:after {
  border-bottom: 3px solid rgb(255, 255, 255);
}
.formContainer .formCheckbox {
  display: flex !important;
  margin-top: 10px;
}
.formContainer .divFormControl {
  display: flex;
  align-items: baseline;
  margin-left: 4px;
}
.formContainer .divFormButton {
  display: flex;
  justify-content: flex-end;
}
.formContainer .formButton, .formContainer .form-button-without-margin {
  font-family: "Montserrat", sans-serif !important;
  font-size: 19px !important;
  font-weight: 800 !important;
  text-transform: capitalize !important;
  background-color: #ffffff !important;
  color: #00cdbe !important;
  width: 133px !important;
  padding: 15px 0px !important;
  border-radius: 39px !important;
  outline: none !important;
  margin-top: 35px !important;
}
.formContainer .formButton:hover, .formContainer .form-button-without-margin:hover {
  cursor: pointer;
}
.formContainer .form-button-without-margin {
  margin-top: 0 !important;
}
.formContainer .loader {
  color: #00cdbe;
}
.formContainer .disabledCls {
  color: grey !important;
}

.main-wrapper-applicant {
  padding: 5rem;
}

.tbl-wrapper {
  padding: 2rem;
}

.main-wrapper-applicant,
.upload-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
}

.upload-section-buttons {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}

.tbl-applicants {
  width: 100% !important;
}

.tbl-applicants .table-umii .tableHead tr th:nth-child(1) {
  max-width: 12% !important;
  min-width: 12% !important;
}
.tbl-applicants .table-umii .tableHead tr th:nth-child(2) {
  max-width: 32% !important;
  min-width: 32% !important;
}
.tbl-applicants .table-umii .tableHead tr th:nth-child(3) {
  max-width: 20% !important;
  min-width: 20% !important;
}
.tbl-applicants .table-umii .tableHead tr th:nth-child(4) {
  max-width: 20% !important;
  min-width: 20% !important;
}
.tbl-applicants .table-umii .tableHead tr th:nth-child(5) {
  max-width: 10% !important;
  min-width: 10% !important;
}
.tbl-applicants .table-umii .tableHead tr th:nth-child(6) {
  max-width: 6% !important;
  min-width: 6% !important;
}
.tbl-applicants .table-umii .tableBody tr td:nth-child(1) {
  max-width: 12% !important;
  min-width: 12% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 30px;
}
.tbl-applicants .table-umii .tableBody tr td:nth-child(2) {
  max-width: 32% !important;
  min-width: 32% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tbl-applicants .table-umii .tableBody tr td:nth-child(3) {
  max-width: 20% !important;
  min-width: 20% !important;
}
.tbl-applicants .table-umii .tableBody tr td:nth-child(4) {
  max-width: 20% !important;
  min-width: 20% !important;
}
.tbl-applicants .table-umii .tableBody tr td:nth-child(5) {
  max-width: 10% !important;
  min-width: 10% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tbl-applicants .table-umii .tableBody tr td:nth-child(6) {
  max-width: 6% !important;
  min-width: 6% !important;
}
